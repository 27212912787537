/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB008D
 * 화면설명: 신계약증권 재발행 개인
 * 접근권한: 모든사용자
 * 작 성 일: 2022.12.23
 * 작 성 자: 김진원
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="신계약증권 재발행" type="subpage" action-type="none" :topButton="true" @on-scroll-bottom="fn_GetService()">
    <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <!-- <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow > -->
            <mo-top-bar color="primary" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '신계약증권 재발행' : '신계약증권 재발행'}}</div>
            </mo-top-bar>  
        
            <!-- 계약자 정보 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area bd-b-Ty1 pb0">  
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
              <div class="list-item w100 pt20 pb0">
                <div class="list-item__contents pb20">
                  <div class="list-item__contents__title fexTy3"> <!-- 2023.01.31 수정> 'fexTy3' 추가 //-->
                    <span class="name txtSkip maxW250 fexInt pr10">{{nbCustCardVO.custNm}}</span>
                    <mo-badge class="badge-sample-badge lightgreen sm" text="계약자" shape="status">계약자</mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="crTy-bk1">{{age}}세</span><em>|</em><span class="crTy-bk1">{{rrn}}</span>
                    </div>
                  </div>
                </div>
              </div> 
              </ur-box-container>
            </ur-box-container>
            <!-- 계약자 정보 //-->          
           </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <!-- 2023.04.13 문구 추가 //-->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="text-gray-box bd-b-Ty1 bd-radius-0">  
              <p class="crTy-bk7 pb10">※ 계약일 + 30 일 이내 계약만 조회 및 신청 가능하며, 고객정보 변경은 계약자 본인인증이 필요하므로 ‘증권재발행’ 화면을 이용하시기 바랍니다.</p>
              <p class="crTy-bk7"><span class="crTy-red2">※ 고액브로셔 발급기준 : </span> 주보험기준 『종신보험 1억↑ or 월초P 100만원↑ or 연금/저축 일시납 보험료 1억↑ or 달러종신  $10만↑』</p>
            </ur-box-container>              
            <!-- 고객정보확인 -->   
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst type-2">  
              <strong class="fs22rem pb20">고객정보확인</strong>
              <ul class="lst">
                <li>
                  <span class="tit wauto min50">휴대폰</span>
                  <span class="txt fexTy3"> <!-- 2023.01.31 수정 : 'fexTy3' 추가 //-->
                    <span class="mr10">{{nbCustCardVO.celno}}</span>
                    <mo-badge v-if="nbCustCardVO.smsReciCnsntYn === '1'" class="badge-sample-badge lightblue sm" text="수신" shape="status">수신</mo-badge> 
                    <mo-badge v-else class="badge-sample-badge lightred sm" text="미수신" shape="status">미수신</mo-badge> 
                  </span>
                </li>
                <li class="pb20 fexTy5 bd-b-Ty2">
                  <span class="tit wauto min50">이메일</span>
                  <span class="txt fexTy3"> <!-- 2023.01.31 수정 : 'fexTy3' 추가 //-->
                    <span class="mr10">{{nbCustCardVO.emailAddr}}</span>
                    <mo-badge v-if="nbCustCardVO.emailReciYn === '1'" class="badge-sample-badge lightblue sm" text="수신" shape="status">수신</mo-badge> 
                    <mo-badge v-else class="badge-sample-badge lightred sm" text="미수신" shape="status">미수신</mo-badge>
                  </span>
                </li>
                <!-- 2023.01.31 수정 : 우편물 수령지 디자인수정 -->
                <li class="fex-clumn">
                  <span class="tit mr0">우편물수령지</span>
                  <ul class="lst-box">
                    <li>
                      <span>{{mailRcppliScCdNm}}</span> 
                      <p>{{homeAddrBinding}}</p>
                    </li>
                  </ul>
                </li>
				<!-- 2023.01.31 수정 : 우편물 수령지 디자인수정 //-->
              </ul>
              <strong class="fs22rem pt25">계약정보확인</strong>
              <span class="crTy-blue3 mr4">*지점발행은 증권재발행 화면을 이용 바랍니다.</span>
            </ur-box-container>
            <!-- 고객정보확인 //-->
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
             <!-- 총건수  -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isCheckAll" @input="fn_CheckAll" size="small" />
                <div v-if="checkCount>0" class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum"><strong class="crTy-blue3">{{checkCount}}</strong> 건 선택</span>
                  </div>
                </div>
                <div v-else class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{totalCount}}</strong> 건</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 총건수  //-->
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- 보유계약조회 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb100">
              <!-- list -->
              <template v-if="totalCount > 0">
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
                  <mo-list :list-data="nBSecusRpblAplListSVO">
                    <template #list-item="{item, index}">
                      <mo-list-item :class="{'on' : item.checked}"> <!-- on item이 체크되어 선택되었음을 표시  --->
                        <mo-checkbox v-model="item.checked" @input="fn_ItemCheck" :disabled="item.uiMesg != '-' && item.uiMesg != '신청가능'"/>
                        <div class="list-item__contents">
                          <div class="list-item__contents__title"> 
                            <span class="name txtSkip">{{item.prdtNm}}</span> <!-- 상품명 -->
                          </div>
                          <div class="list-item__contents__info">
                            <span class="fs16rem fwb txtSkip maxW70">{{item.insrdNmMask}}</span><em>|</em> <!-- 피보험자 -->
                            <span class="crTy-bk7 fs14rem mr10">계약일 </span><span>{{item.contYmd}}</span> <!-- 계약일 -->
                          </div>
                          <div class="list-item__contents__info">
                            <span class="crTy-bk7 fs14rem mr10">계약번호 </span><span>{{item.contNoMask}}</span><em>|</em> <!-- 계약번호 -->
                            <span class="crTy-bk7 fs14rem mr10">상태 </span><span>{{item.contStatNm}}</span><em>|</em> <!-- 계약상태 -->
                            <span class="crTy-bk7 fs14rem mr10">보험료</span><span>{{item.smtotPrm}}원</span> <!-- 보험료 -->
                          </div>
                          <div class="list-item__contents__info fexTy5 pt7"><!-- :disabled="item.uiMesg != '-' && item.uiMesg != '신청가능'"-->
                            <msp-bottom-select ref="bottomSelect" :items="receiveItems" v-model="item.uiSecusCvancMthCd" @input="fn_changeUiSecusCvancMthCd(item)" class="ns-dropdown-sheet mr10 display-inline-flex select-type1" placeholder="받는방법" bottom-title="받는방법" closeBtn scrolling/>
                            <mo-badge class="badge-sample-badge lightblue sm"  text="신청가능" shape="status" v-if="item.uiMesg === '신청가능'">{{item.uiMesg}}</mo-badge> 
                            <span class="ns-tool-tip display-inline-flex align-item-center" v-else-if="item.uiMesg != '-'">
                              <mo-badge class="badge-sample-badge lightred sm" text="신청불가능" shape="status">{{item.uiMesg}}</mo-badge> 
                              <mo-icon :id="`location${index}`" icon="msp-tool-tip" class="ml5">tool-tip</mo-icon>
                              <mo-tooltip :target-id="`location${index}`" class="tool-tip-box w50vw">{{item.trtRsltCdNm}}</mo-tooltip>
                            </span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>
              </template>
              <!-- list end -->
              <!-- list count 0 -->
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray"><p class="mt20">데이터가 존재하지 않습니다.</p></span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
              <!-- list count 0 end -->
            </ur-box-container>
            <!-- 보유계약조회 //-->

            <!-- 하단 버튼영역 -->
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$router.go(-1)">이전</mo-button>
                <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_next" :disabled="checkCount===0">다음</mo-button>
              </div>
            </ur-box-container>
            <!-- 하단 버튼영역 //-->  

          </template>
          <!-- scroll 영역 //-->

        </mo-collapsing-header-view>
       </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPNB008D",
  screenId: "MSPNB008D",
  components: {},
  props: {
    contrPartnerId: {type: String} // 고객ID
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SelCustInfo() //고객 정보 조회

  },
  mounted () {
    // 스크롤 애니메이션 instance 선언
    //this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    //document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      age: '', //연령
      rrn: '', //주번
      isCheckAll: false,
      nbCustCardVO: {}, //고객정보
      nBSecusRpblAplListSVO: [], //보유계약리스트
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: '1'}], //리스트 더보기 기능
      reason: '23', // 12: 분실, 23: 보장확인
      homeAddrBinding:'', //주소
      mailRcppliScCdNm:'', //수령처
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    receiveItems() {
      let items = [
                    {value: '0', text: '선택'},
                    {value: '06', text: '모바일'},
                    {value: '01', text: '이메일'},
                    {value: '07', text: '고액브로셔'},
                    // {value: '02', text: '지점발행'},
                    {value: '04', text: '자택 일반우편'},
                    {value: '05', text: '직장 일반우편'},
                  ]
      return items
    },
    totalCount() {
      return this.nBSecusRpblAplListSVO.length
    },
    isNoSelectedReceive() {
      return this.nBSecusRpblAplListSVO.filter(el => el.checked && el.uiSecusCvancMthCd === '0').length
    },
    checkCount() {
      return this.nBSecusRpblAplListSVO.filter(el => el.checked).length
    },
  },




  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_ClickBtnPrevious () {this.$router.go(-1)},


    fn_CheckAll(data) {
      this.nBSecusRpblAplListSVO.forEach(element => {
        if( element.uiMesg == '-' || element.uiMesg == '신청가능' ){
          element.checked = data
        }
      })

      this.fn_ItemCheck()
    },

    fn_ItemCheck() {
      let unCheckCount = this.nBSecusRpblAplListSVO.filter(element => !element.checked).length

      //console.log('unCheckCount : ', unCheckCount)

      this.isCheckAll = unCheckCount === 0
    },

    /******************************************************************************
    * Function명 : fn_SelCustInfo
    * 설명       : 고객정보 조회(융자 직장정보 포함)
    ******************************************************************************/
    fn_SelCustInfo () {
      let params = {
        custId: this.$props.contrPartnerId
      }

      let lv_Vm = this
      this.post(this, params, 'txTSSNB12S1', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if( response.body !== null ){
            if( lv_Vm.isNotEmpty(response.body.custId) ){

              // 정상조회처리 조회화면으로 리턴
              let custInfo = response.body

              //console.log('custInfo : ', custInfo)

              //주번, 연령
              if( lv_Vm.isNotEmpty(custInfo.rrn) ){
                lv_Vm.age = lv_Vm.$pmUtil.fn_GetAge(lv_Vm.$bizUtil, custInfo.rrn.substring(0, 6)) // 연령 셋팅.
                lv_Vm.rrn = custInfo.rrn.replace(/(^\d{6})(\d{1}).*/, '$1-$2******') //ex : 2301013******
              }

              // address setting start
              if( lv_Vm.isNotEmpty(custInfo.mailRcppliScCd) ){

                if( custInfo.mailRcppliScCd === '0002'){ // 자택이면
                  lv_Vm.mailRcppliScCdNm = '자택'

                  if ( lv_Vm.isNotEmpty(custInfo.homPstcd) ){
                    if (custInfo.homeAddrScCd === '2') { // 자택 신주소인 경우
                      lv_Vm.homeAddrBinding = `(${custInfo.homPstcd}) ${custInfo.homAddr} ${custInfo.homebuilding} ${custInfo.homDtlad} ${custInfo.homestrSuppl1}`
                    } else if (custInfo.homeAddrScCd === '1') { // 자택 구 주소인 경우
                      lv_Vm.homeAddrBinding = `(${custInfo.homPstcd}) ${custInfo.homAddr} ${custInfo.homDtlad}`
                    }
                  }

                } else if (custInfo.mailRcppliScCd === '0003') { // 직장이면
                  lv_Vm.mailRcppliScCdNm = '직장'

                  if( lv_Vm.isNotEmpty(custInfo.jobpPstcd) ){
                    if (custInfo.jobAddrScCd === '2') { // 직장이 신주소인 경우
                      lv_Vm.homeAddrBinding = `(${custInfo.jobpPstcd}) ${custInfo.jobpAddr} ${custInfo.jobpbuilding} ${custInfo.jobpDtlad} ${custInfo.jobpstrSuppl1}`
                    } else if (custInfo.jobAddrScCd === '1') { // 직장 구 주소인 경우
                      lv_Vm.homeAddrBinding = `(${custInfo.jobpPstcd}) ${custInfo.jobpAddr} ${custInfo.jobpDtlad}`
                    }
                  }

                }

              } // address setting end

              lv_Vm.nbCustCardVO = custInfo // 조회된 고객 정보
              lv_Vm.fn_GetService()

            } else {
              // 이름과 식별번호가 일치하지 않는 케이스 처리
              lv_Vm.nbCustCardVO = {custNm: '', knbFrno: '', knbBkno: '', knbBknoCipher: ''}
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('nb')['ENBC005']) // 고객 조회 정보가 없습니다.

            }
          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('nb')['ENBC009']) // 에러가 발생했습니다.

          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },



    /******************************************************************************
    * Function명 : fn_GetService
    * 설명       : 신청보유계약조회 호출
    ******************************************************************************/
    fn_GetService () {

      // 다음 페이지 번호 [{stndKeyId: 'next_key', stndKeyVal: '1'}]
      if( this.$bizUtil.isEmpty(this.stndKeyList[0].stndKeyVal) ){
        return
      }

      let params = {
        bpid: this.$props.contrPartnerId, // 고객BPID
        ltstContFg: 'X'
      }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSNB08S1', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseList = response.body.nBSecusRpblAplListSVO
            
            if (lv_Vm.isNotEmpty(responseList) && responseList.length > 0) {
              responseList.forEach(el => el.checked = false) // 결과값에는 checked 가 없다. 이 상태로 mo-list 에 바인딩 되면, mo-checkbox 의 작동에 버그가 생긴다. 하~~ 하루 종일 삽질을 ㅡ,.ㅡ
              lv_Vm.nBSecusRpblAplListSVO = lv_Vm.nBSecusRpblAplListSVO.concat(responseList)
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList // 더보기 키값

              //console.log('stnd key list : ', lv_Vm.stndKeyList)
            }

            // 202007 외화보험 적용
            for (let i = 0; i < lv_Vm.nBSecusRpblAplListSVO.length; i++) {
              lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm = lv_Vm.$bizUtil.replaceAll(lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm, ',', '')
              lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm = lv_Vm.$bizUtil.numberWithCommasCurr(lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm, lv_Vm.nBSecusRpblAplListSVO[i].crncyCd, 'Y', 'Y', 4)
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },



    /******************************************************************************
    * Function명 : fn_RecvMthCheck
    * 설명       : 받는방법 체크 하여, 선택 할 수 없는 항목은 초기화 한다.
    ******************************************************************************/
    fn_RecvMthCheck(msgCode, item){
      item.checked = false
      item.uiSecusCvancMthCd = '0'
      item.uiMesg = '-'

      let message = this.$t('nb')[msgCode] + '\n' +
                    this.$t('nb')['ENBC037'] // 계약자 본인인증을 통해\n고객정보 변경 후 신청하시길 바랍니다
      this.getStore('confirm').dispatch('ADD', message)
    },

    /*********************************************************
     * Function명: fn_changeUiSecusCvancMthCd
     * 설명: 받는방법(콤보:fdp-select)를 변경선택 시, 변경하는 이벤트 핸들러
     * Params: _selected {Object}: 선택 이벤트 전달 객체 <필수>
     *         _item {Object}: 받는방법 목록 중 현재 선택된 아이템
     *
     * Return: N/A
     *********************************************************/
    fn_changeUiSecusCvancMthCd(item) {
      //console.log('item : ', item)

      const custInfo = this.nbCustCardVO
      const code = item.uiSecusCvancMthCd
      
      // 선택인 경우
      if( code === '0' ){
        item.checked = false
        item.uiMesg = '-'
        return

      } else if( code === '01' ) { // 이메일
        if (this.$bizUtil.isEmpty(custInfo.emailAddr) || custInfo.emailReciYn !== '1') {
          this.fn_RecvMthCheck('ENBC022', item) // '이메일이 등록되지 않았거나 수신거부 입니다'
          return
        }

      } else if( code === '04' ) { // 자택 일반우편
        if (this.$bizUtil.isEmpty(custInfo.homAddr) || (custInfo.mailRcppliScCd !== '0002')) {
          this.fn_RecvMthCheck('ENBC024', item) // '우편물수령지가 자택이 아니거나 자택주소 정보가 등록되어 있지 않습니다'
          return
        }

      } else if( code === '05' ) { // 직장 일반우편
        if (this.$bizUtil.isEmpty(custInfo.jobpAddr) || (custInfo.mailRcppliScCd !== '0003')) {
          this.fn_RecvMthCheck('ENBC025', item) // '우편물수령지가 직장이 아니거나 직장주소 정보가 등록되어 있지 않습니다'
          return
        }

      } else if( code === '06' ) { //모바일
        if (this.$bizUtil.isEmpty(custInfo.celno) || custInfo.smsReciCnsntYn !== '1') {
          this.fn_RecvMthCheck('ENBC023', item) // '모바일이 등록되지 않았거나 수신거부 입니다'
          return
        }

      } else if( code === '07' ) { //고액브로셔 선택 시 메시지 표시 만 하고 프로세스는 진행
        this.getStore('confirm').dispatch('ADD', this.$t('nb')['ENBC038']) // 고액브로셔 발급기준을 확인 후 신청하세요.
      }

      let text = this.receiveItems.find(element => element.value == code).text
      item.uiSecusCvancMthCdNm = text
      item.ck = true

      // inDTO 셋팅
      let inDto = this.$bizUtil.cloneDeep(item)
      inDto.rpblRsnCd = this.reason // 재발행사유코드

      if (item.planId !== item.contNo) inDto.reprContNo = ''

      // 증권전달방법코드
      if (item.uiSecusCvancMthCd === '01') { // 이메일
        inDto.secusCvancMthCd = '4' // 증권전달방법코드(이메일)
        inDto.pblPlcScCd = '30' // 발행장소구분코드(이메일)

      } else if (item.uiSecusCvancMthCd === '02') { // 방문 - 현지발행-
        inDto.secusCvancMthCd = '1' // 증권전달방법코드(방문)
        inDto.pblPlcScCd = '20' // 발행장소구분코드(현지)

      } else if (item.uiSecusCvancMthCd === '04') { // 자택일반우편
        inDto.secusCvancMthCd = '2' // 증권전달방법코드(자택일반우편)
        inDto.pblPlcScCd = '10' // 발행장소구분코드(본사)

      } else if (item.uiSecusCvancMthCd === '05') { // 직장일반우편
        inDto.secusCvancMthCd = '3' // 증권전달방법코드(직장일반우편)
        inDto.pblPlcScCd = '10' // 발행장소구분코드(본사)

      } else if (item.uiSecusCvancMthCd === '06') { // 모바일
        inDto.secusCvancMthCd = 'M' // 증권전달방법코드(모바일)
        inDto.pblPlcScCd = '40' // 발행장소구분코드(모바일)

      } else if (item.uiSecusCvancMthCd === '07') { // 고액브로셔
        inDto.secusCvancMthCd = '1' // 증권전달방법코드(방문)
        inDto.pblPlcScCd = '10' // 발행장소구분코드(본사)
        inDto.secusShpCd = 'C' // 증권형태코드(브로셔)

      }

      let params = {
        nBSecusRpblAplListSVO: [inDto]
      }

      let lv_Vm = this
      this.post(this, params, 'txTSSNB08S2', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.nBSecusRpblAplListSVO

            if( lv_Vm.isNotEmpty(responseList) && responseList.length > 0 ){
              //console.log('responseList : ', responseList)

              if (item.contNo === responseList[0].contNo) {
                if( responseList[0].uiMesg === '신청가능' ){
                  item.checked = true
                } else {
                  item.checked = false
                }

                lv_Vm.fn_ItemCheck()

                item.uiMesg = responseList[0].uiMesg
                item.trtRsltCdNm = responseList[0].trtRsltCdNm // 처리결과코드명(신청결과)
                item.secusShpCd = responseList[0].secusShpCd // 증권형태코드
                item.aplFofOrgNo = responseList[0].aplFofOrgNo // 신청지점조직번호
                item.emailAddr = responseList[0].emailAddr // 이메일주소
                item.faxTelno = responseList[0].faxTelno // 팩스전화번호
                item.pblPlcScCd = responseList[0].pblPlcScCd // 발행장소구분코드
                item.secusCvancMthCd = responseList[0].secusCvancMthCd // 증권전달방법코드
                item.pblPlcScCd = responseList[0].pblPlcScCd // 발행장소구분코드
                item.prcsfCd = responseList[0].prcsfCd // 프로세스 프래그코드
                item.sno = responseList[0].sno // 일련번호
                item.systmKey = responseList[0].systmKey // 시스템키
                item.aplDofOrgNo = responseList[0].aplDofOrgNo // 신청지역단조직번호
                item.contAmt = responseList[0].contAmt // 계약금액
                item.aplYmd = responseList[0].aplYmd // 신청일자
                item.pblYmd = responseList[0].pblYmd // 발행일자
                item.secusPblScCd = responseList[0].secusPblScCd // 증권발행구분코드 01:개인, 02:통합
                item.planId = responseList[0].planId // 설계ID
                item.contNo = responseList[0].contNo // 계약번호
                item.pblNo = responseList[0].pblNo // 발행번호
                item.duseYmd = responseList[0].duseYmd // 폐기일자
                item.aplrEno = responseList[0].aplrEno // 신청자사번
                item.rrnFrnoNo = responseList[0].rrnFrnoNo // 주민등록번호앞자리번호
                item.rrnBknoNo = responseList[0].rrnBknoNo // 주민등록번호뒷자리번호
                item.expYmd = responseList[0].expYmd // 만기일자
                item.ltpadYm = responseList[0].ltpadYm // 종납년월
                item.ltpadNts = responseList[0].ltpadNts // 종납횟수
                item.ltpadYmd = responseList.ltpadYmd // 종납일자
                item.padMms = responseList[0].padMms // 납입개월수
                item.padCylCd = responseList[0].padCylCd // 납입주기코드
                item.padCylNm = responseList[0].padCylNm // 납입주기명
                item.contStatCd = responseList[0].contStatCd // 계약상태코드
                item.clmnyFofOrgNo = responseList[0].clmnyFofOrgNo // 수금지점조직번호
                item.clmnyFofNm = responseList[0].clmnyFofNm // 수금지점명
                item.aplnPadScCd = responseList[0].aplnPadScCd // 자동대출납입구분코드
                item.aplnPadScCdNm = responseList[0].aplnPadScCdNm // 자동대출납입구분코드명
                item.pllnYn = responseList[0].pllnYn // 보험계약대출여부
                item.padStatCd = responseList[0].padStatCd // 납입상태코드
                item.padStatCdNm = responseList[0].padStatCdNm // 납입상태코드명
                item.contScCd = responseList[0].contScCd // 계약구분코드
                item.busnCnctRfltYn = responseList[0].busnCnctRfltYn // 업무연락반영여부
                item.contAmt = responseList[0].contAmt // 계약금액
                item.prcd = responseList[0].prcd // 상품코드
                item.prdtVcd = responseList[0].prdtVcd // 상품버전코드
                item.reprContNo = responseList[0].reprContNo // 대표계약번호
                item.rpblRsnCd = responseList[0].rpblRsnCd // 재발행사유코드
                //lv_Vm.fn_rowClick(_item, responseList[0].uiMesg)
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },


    /******************************************************************************
    * Function명 : fn_next
    * 설명       : 다음 버튼 클릭
    ******************************************************************************/
    fn_next() {
      // 받는방법을 선택 하지 않은 건 체크.
      if( this.isNoSelectedReceive > 0 ){
        this.getStore('confirm').dispatch('ADD', this.$t('nb')['ENBC021']) // 받는 방법을 선택하지 않은 계약이 있습니다.
        return
      }

      this.fn_GetP1Service() // 재발행신청
    },



    /******************************************************************************
    * Function명 : fn_GetP1Service
    * 설명       : 재발행신청
    ******************************************************************************/
    fn_GetP1Service () {
      let checkList = this.nBSecusRpblAplListSVO.filter(item => item.checked)

      let params = {
        nBSecusRpblAplListSVO: checkList
      }

      //console.log(params)
      
      let lv_Vm = this
      this.post(this, params, 'txTSSNB08P1', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.nBSecusRpblAplListSVO

            if (responseList !== null && responseList.length > 0) {
              lv_Vm.$emit('next', responseList) //MSPNB002M > fn_Result
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }

        }).catch(function (error) {
          window.vue.getStore('progress').dispatch('SUB')
          window.vue.error(error)

        })
    },


    //개발 편의 not empty
    isNotEmpty(value) {
      return !this.$bizUtil.isEmpty(value)
    },
  }
};
</script>
<style scoped>
</style>