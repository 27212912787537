/*
 * 업무구분: 신계약증권 재발행
 * 화 면 명: MSPNB002M
 * 화면설명: 신계약증권 재발행 메인
 * 접근권한: 모든사용자
 * 작 성 일: 2022.12.23
 * 작 성 자: 김진원
 */
 <template>
  <MSPNB008D      v-if="currentStep === 1 && $route.params.contrPgScCd === '0'" :contrPartnerId="$route.params.contrPartnerId" @next="fn_Result"></MSPNB008D>
  <MSPNB009D v-else-if="currentStep === 1 && $route.params.contrPgScCd === '1'" :contrPartnerId="$route.params.contrPartnerId" @next="fn_Result"></MSPNB009D>
  <MSPNB010D v-else-if="currentStep === 2" :nBSecusRpblAplListSVO="nBSecusRpblAplListSVO"></MSPNB010D>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MSPNB008D from '@/ui/nb/MSPNB008D' // STEP1. 신계약증권 재발행 개인 - 보유계약 조회
import MSPNB009D from '@/ui/nb/MSPNB009D' // STEP1. 신계약증권 재발행 법인 - 보유계약 조회
import MSPNB010D from '@/ui/nb/MSPNB010D' // STEP2. 증권재발행 신청결과확인

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPNB002M',
  // 현재 화면 ID
  screenId: 'MSPNB002M',
  // 컴포넌트 선언
  components: {
    MSPNB008D, //개인
    MSPNB009D, //법인/단체
    MSPNB010D  //신청결과
  },
  props: {},

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      currentStep: 1,
      nBSecusRpblAplListSVO: [], //보유계약리스트
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {

  },
  /** watch 정의 영역 */
  watch: {
  },
  created () {
    console.log('contrPartnerId : ', this.$route.params.contrPartnerId, 'contrPgScCd : ', this.$route.params.contrPgScCd)
  },
  beforeDestroy () {

  },
  mounted() {
    //공통 가이드라인 : LOG 관련 화면마다 넣어 줘야 하는 script
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_Result
    * 설명       : 개인고객 결과
    ******************************************************************************/
    fn_Result(data) {
      console.log('==>> result : ', data)
      //debugger
      this.nBSecusRpblAplListSVO = data // 신청 결과 리스트
      this.currentStep++
    },

    
    //개발 편의 not empty
    isNotEmpty(value) {
      return !this.$bizUtil.isEmpty(value)
    }
  }
  
  
}
</script>